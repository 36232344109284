interface Link {
    link: string,
    name: string
}

export const links: Link[] = [{
    link: '/',
    name: 'Inicio',
},{
    link: '/contacto',
    name: 'Contacto',
},{
    link: '/planes',
    name: 'Planes',
},{
    link: '/pagos',
    name: 'Pagos',
},{
    link: '/solicitar-instalacion',
    name: 'Solicitar Instalación',
}]

export const intranet: Link[] = [{
    link: '/registrar',
    name: 'Registrarse'
}, {
    link: '/iniciar-sesion',
    name: 'Iniciar Sesion'
}]
