import { createBrowserRouter } from 'react-router-dom';
import loadable from '@loadable/component';
import Layout from 'components/Layout';

const Home = loadable(() => import(/* webpackChunkName: 'nosotros' */ './Home'))
const Nosotros = loadable(() => import(/* webpackChunkName: 'nosotros' */ './Nosotros'));
const Servicios = loadable(() => import(/* webpackChunkName: 'servicios' */  './Servicios'));
const Soporte = loadable(() => import(/* webpackChunkName: 'soporte' */  './Soporte'));
const Pagos = loadable(() => import(/* webpackChunkName: 'pagos' */  './Pagos'));
const ErrorFound = loadable(() => import(/* webpackChunkName: 'error_found' */  './ErrorFound'));
const Apk = loadable(() => import(/* webpackChunkName: 'error_found' */  './Apk'));
//const SolicitarServicio = loadable(() => import('./SolicitarServicio'));

const routeConfig = createBrowserRouter([{
    path: '/',
    element: <Layout />,
    children: [{
        index: true,
        element: <Home />
    }, {
        path: 'contacto',
        element: <Nosotros />
    }, {
        path: 'planes',
        element: <Servicios />
    }, {
        path: 'pagos',
        element: <Pagos />
    }, {
        path: 'solicitar-instalacion',
        element: <Soporte />
    },
    {
      path: 'apk',
      element: <Apk />
    },
    ]
}, {
    path: '*',
    element: <ErrorFound />
}])

/*
const routeConfig = createBrowserRouter([{
    index: true,
    element: <Home />
},{
    path: 'nosotros',
    element: <Nosotros />
},{
    path: 'planes',
    element: <Servicios />
}, {
    path: 'solicitar',
    children: [{
        index: true,
        element: <ErrorFound />
    }, {
        path: 'corporativo',
        element: <SolicitarServicio tipo='Corporativo' />
    }, {
        path: 'familiar',
        element: <SolicitarServicio tipo='Familiar' />
    }]
},{
    path: 'pagos',
    element: <Pagos />
},{
    path: 'soporte',
    element: <Soporte />
},{
    path: '*',
    element: <ErrorFound />
}]);
*/
export default routeConfig;
