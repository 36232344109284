import { useState, Dispatch, SetStateAction, useEffect } from 'react';
import { ScrollRestoration, useLocation, useOutlet, useOutletContext } from 'react-router-dom';
import Spinner from '../Spinner';
import Navbar from '../Navbar';
import Footer from '../Footer';
import Copyright from '../Copyright';
import BackToTop from '../BackToTop';

function Layout() {
    const [spinner, setSpinner] = useState(true)
    const oulet = useOutlet([spinner, setSpinner])
    const { pathname } = useLocation()

    useEffect(() => {
        setSpinner(true)
        // eslint-disable-next-line
    }, [pathname])

    return (
        <>
            <ScrollRestoration />
            <Spinner show={spinner} />
            {oulet !== null && (
                <>
                    <Navbar />
                    {oulet}
                    <Footer />
                    <Copyright />
                    <BackToTop />
                </>
            )}
        </>
    );
}

export function useSpinner(): [boolean, Dispatch<SetStateAction<boolean>>]  {
    const [value, setSpinner] = useOutletContext<[boolean, Dispatch<SetStateAction<boolean>>]>()

    useEffect(() => {
        const show = setTimeout(() => {
            setSpinner(false)
        }, 300)

        return () => {
            clearTimeout(show)
        }
    }, [])

    return [value, setSpinner];
}



export default Layout;
