import { useRef, useEffect } from "react";

export function useResizeNavbar() {
    const navbar = useRef<HTMLDivElement>(null);
    const image = useRef<HTMLImageElement>(null);

    useEffect(() => {
        if (!navbar.current) return;

        const ele = navbar.current;

        if (!image.current) return;

        const sucrenetImg = image.current;

        const resize = () => {

            if (window.innerWidth < 865) {
                sucrenetImg.style.width = '150px';
                if (ele.classList.contains('fixed-top')) ele.classList.remove('fixed-top');
            } else if (window.innerWidth < 1175) {
                sucrenetImg.style.width = '150px';
                if (!ele.classList.contains('fixed-top')) ele.classList.add('fixed-top');
            } else if (window.innerWidth < 1200) {
                sucrenetImg.style.width = '175px';
                if (!ele.classList.contains('fixed-top')) ele.classList.add('fixed-top');
            } else if (window.innerWidth < 1225) {
                sucrenetImg.style.width = '200px';
                if (!ele.classList.contains('fixed-top')) ele.classList.add('fixed-top');
            } else if (window.innerWidth < 1250) {
                sucrenetImg.style.width = '225px';
                if (!ele.classList.contains('fixed-top')) ele.classList.add('fixed-top');
            } else if (window.innerWidth < 1275) {
                sucrenetImg.style.width = '250px';
                if (!ele.classList.contains('fixed-top')) ele.classList.add('fixed-top');
            } else if (window.innerWidth < 1300) {
                sucrenetImg.style.width = '275px';
                if (!ele.classList.contains('fixed-top')) ele.classList.add('fixed-top');
            } else {
                sucrenetImg.style.width = '300px';
                if (!ele.classList.contains('fixed-top')) ele.classList.add('fixed-top');
            }
        }

        resize();

        const scroll = () => {
            if (window.scrollY > 45) {
                if (!ele.classList.contains("bg-white") || !ele.classList.contains("shadow")) {
                    ele.classList.add("bg-white", "shadow");
                }
            } else {
                if (ele.classList.contains("bg-white") && ele.classList.contains("shadow")) {
                    ele.classList.remove("bg-white", "shadow");
                }
            }
        }

        window.addEventListener('resize', resize);
        window.addEventListener('scroll', scroll);

        return () => {
            window.removeEventListener('resize', resize);
            window.removeEventListener('scroll', scroll);
        }
    }, []);

    return {
        navbar,
        image
    };
}