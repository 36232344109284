function Spinner ({ show }: {show: boolean}) {
    return (
        <div 
            id="spinner"
            className={`bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center ${show === true ? 'show' : ''}`}
        >
            <div className="spinner-border text-primary" role="status" style={{width: '3rem', height: '3rem'}}></div>
        </div>
    );
}

export default Spinner;